html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  background-color: #0b0b0b;
}

#root {
  width: 100%;
  height: 100vh;
}

/* width */
::-webkit-scrollbar {
  width: 0.7rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1a1a1a;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 0.35rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-moz-selection {
  /* Code for Firefox */
  color: #40280b;
  background: #ee7127;
}

::selection {
  color: #40280b;
  background: #ee7127;
}

svg {
  pointer-events: none;
}

svg:has(.recharts-pie) {
  pointer-events: initial;
}

.recharts-pie .recharts-sector {
  transition: all 0.1s ease-out;
  cursor: pointer;
}
